<template lang="pug">
div
  .container.container-homepage.mt-4
    .row
      .col-1
      .col-10.pt-4.text-center.d-flex
        .col-1.col-lg-3
        .col-10.col-lg-6
          br
          br
          .font-lg.font-weight-medium.coming-soon-badge.d-flex.align-items-center
            .px-4 Coming Soon
          br
          h1.hb1.d-none.d-lg-block
            | Conversations and commentary around employee benefits, work culture and best HR practices
          h3.hb3.d-block.d-lg-none
            | Conversations and commentary around employee benefits, work culture and best HR practices
      img.left-hero-image(:src="require(`@/assets/images/community-left-hero.svg`)")
      img.right-hero-image(:src="require(`@/assets/images/community-right-hero.svg`)")
    .page-break
    .row.no-gutters
      .col-lg-1
      .col-lg-10.px-2
        .font-hc.text-gray-500.text-center Past events
        br
        .row
          past-events-card.col-12.col-lg-6(v-for="event in pastEvents", :key="event.id", :event="event")
    .page-break
    .row.no-gutters.py-4.mx-2
      .col-lg-1
      .col-lg-10.px-4
        .row.sign-up-container.overflow-hidden
          .col-12.col-lg-8
            br
            br
            .d-none.d-lg-block
              h3.hb3 Like what you see? Our next event is right around the corner
              h3.text-mustard-900 Stay in touch and you’ll be the first to know
            .d-block.d-lg-none
              h5.hb5.text-center Like what you see? Our next event is right around the corner
              h5.text-mustard-900.text-center Stay in touch and you’ll be the first to know
            br
            b-form(@submit.prevent="submitNextEventSignUp")
              .input-group.px-0.py-3.d-none.d-xl-flex
                n-inline-input.w-50.input-tiber.rounded-right-none.d-inline-block(
                  inputType="email",
                  v-model="heroEmail",
                  :required="true",
                  placeholder="Enter your favourite email",
                  imageIcon="mail")
                n-button.d-inline-block.rounded-left-none.px-4.py-2(variant="tiber", buttonText="Sign me up")

              .input-group.w-100.px-0.py-xl-3.d-flex.d-xl-none
                n-inline-input.w-100.input-tiber.rounded-bottom-none(
                  inputType="email",
                  v-model="heroEmail",
                  name="email",
                  :required="true",
                  placeholder="Enter your favourite email",
                  imageIcon="mail")
              n-button.px-3.mb-3.rounded-top-none.d-block.d-xl-none(variant="tiber", block, buttonText="Sign me up")

            .d-none.d-lg-block.font-weight-medium.text-mustard-900
              | Sign up to receive emails about our upcoming events and our monthly newsletter
            .d-block.d-lg-none.text-center.font-weight-medium.text-mustard-900
              | Sign up to receive emails about our upcoming events and our monthly newsletter
            br
            br
          .col-12.col-lg-4
            img.d-block.events-image(:src="require(`@/assets/images/community-next-events.svg`)")
    .page-break
    .row.no-gutters.px-3
      .offset-lg-1
        h3.inter.font-weight-semibold Want to learn more about Employee Health Insurance and Benefits?
    br
    .row.no-gutters
      .col-12.col-lg-10.offset-lg-1
        .row.flex-nowrap.flex-lg-wrap.overflow-x-auto.no-gutters
          latest-blog.d-none.d-lg-block.py-4.px-3(
            v-for="blog in latestBlogs",
            :key="`community-page-latest-blog-web-${blog.id}`",
            :blog="blog")
  .row.no-gutters.px-lg-4
    .col-12.col-lg-10.offset-lg-1
      .row.flex-nowrap.flex-lg-wrap.overflow-x-auto.no-gutters
        b-card.d-block.d-lg-none.col-10.latest-blog-card.py-2.m-3.h-auto(
          v-for="blog in latestBlogs",
          :key="`community-page-latest-blog-mobile-${blog.id}`",
          style="width: 400%;")
          latest-blog(:blog="blog")
  .container.container-homepage.mt-4
    .pb-4.text-center
      b-link(href="https://blog.getnovaapp.com/", target="_blank")
        n-button.rounded-sides.my-4.px-4(variant="outline-secondary2", buttonText="View more articles ->")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NInlineInput from "@/components/NovaInlineInput.vue";
import utils from "@/utils";
import gql from "graphql-tag";
import resDefs from "../forms/definitions";
import LatestBlog from "./components/LatestBlog.vue";
import PastEventsCard from "./components/PastEventsCard.vue";
export default {
  name: "CommunityPage",
  components: {
    PastEventsCard,
    NButton,
    NInlineInput,
    LatestBlog,
  },
  data() {
    return {
      latestBlogs: [],
      pastEvents: [
        {
          id: 1001,
          url: "https://www.youtube.com/watch?v=SGcHCyzgmAU",
          image: "yt-thumb-1.jpg",
          title: "How to train your Empathy Muscle and Improve Inclusion?",
          watch_time: 60,
          date: "19th December",
        },
        {
          id: 2002,
          url: "https://www.youtube.com/watch?v=M_dyCOkdbSA",
          image: "yt-thumb-2.jpg",
          title:
            "Impact of Virtualisation and How are HR's reimagining the post pandemic workplace",
          watch_time: 70,
          date: "26th December",
        },
      ],
      heroEmail: "",
    };
  },
  async created() {
    this.$Progress.start();

    this.$apollo.addSmartQuery("latestBlogs", {
      query: gql`
        query ghostBlogsQuery($blogset: String) {
          ghostBlogs(blogset: $blogset)
        }
      `,
      variables: () => ({
        blogset: "latest",
      }),
      update(data) {
        return data.ghostBlogs;
      },
    });

    this.$Progress.finish();
  },
  methods: {
    showModal() {
      this.$bvModal.show("info-modal-1");
    },

    openCalendlyPopup() {
      utils.openCalendlyPopup();
    },

    async submitNextEventSignUp() {
      try {
        const result = await this.$apollo.mutate({
          mutation: resDefs.starterForm.createFormResponse,
          variables: {
            meta: {
              data: {
                contact: this.heroEmail,
              },
              landing: "CommunityPageNextEventSignUp",
            },
            formSlug: "website-leads",
          },
        });
        if (result) {
          this.$store.commit("addAlert", {
            variant: "success",
            message: "Thanks, Our team will get back to you soon!",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.input-tiber {
  &::placeholder {
    color: $gray-500;
    @extend .font-weight-medium, .font-md;
  }
}
input {
  border-width: 2px;
}
.text-mustard-900 {
  color: $mustard-900;
}
.nav-link {
  color: $gray-700;
  margin-right: 5rem;
}

.logos > div > img {
  height: 1.6rem;
}

.left-hero-image {
  height: 400px;
  width: 25%;
  position: absolute;
  left: 0;
}
.right-hero-image {
  height: 400px;
  width: 25%;
  position: absolute;
  right: 0;
}
@media (min-width: 576px) {
  .left-hero-image {
    height: 500px;
  }
  .right-hero-image {
    height: 500px;
  }
}
.events-image {
  height: auto;
  margin-left: -45px;
  margin-right: -45px;
  width: calc(100% + 90px);
}

@media (min-width: 992px) {
  .events-image {
    margin: 0;
    height: 100%;
    width: auto;
    position: absolute;
    right: -230px;
  }
}

@media (min-width: 1200px) {
  .events-image {
    right: -30px;
  }
}

.coming-soon-badge {
  background: $mustard-200;
  height: 1.75rem;
  width: fit-content;
  margin: auto;
  border-radius: 5rem;
  vertical-align: middle;
}
.sign-up-wrapper {
  // padding: 0;
}
@media (min-width: 576px) {
  .sign-up-wrapper {
    padding: 0 30px;
  }
}
.sign-up-container {
  background: $mustard-200;
  border-radius: 0.5rem;
  padding: 0;
}
@media (min-width: 576px) {
  .sign-up-container {
    padding: 0 30px;
  }
}

.input-group-append .btn {
  z-index: 1;
}
.rounded-top-none {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.rounded-bottom-none {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.input-tiber {
  background-color: $white;
  border-radius: 0.25rem;
  & > * > * {
    border-color: $tiber !important;
  }
}
</style>
