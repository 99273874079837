<template lang="pug">
.my-4.px-4
  b-link.no-styles(:href="event.url", target="_blank")
    b-card.col-12.px-0.h-100
      .w-100.rounded
        img.w-100(:src="require(`@/assets/images/${event.image}`)")
      br
      h4.hb4.mb-4 {{ event.title }}
      br

      .row.mx-0.text-gray-700.bottom-row
        .row.mx-0.align-items-center.pr-4.width-max-content
          span.icon.icon-calendar.pr-2
          span.align-middle {{ event.date }}
        .row.mx-0.align-items-center.pr-4.width-max-content
          span.icon.icon-play-circle.pr-2
          span.align-middle {{ event.watch_time }} min{{event.watch_time!==1?'s':''}}
        .row.mx-0.align-items-center.width-max-content.ml-auto
          span.icon.icon-launch
</template>

<script>
export default {
  name: "PastEventsCard",
  props: {
    event: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.left-section {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.text-teal-800 {
  color: $teal-800;
}
.icon {
  font-size: 1.5rem;
}
.no-styles {
  text-decoration: inherit;
  color: inherit;
}
.bottom-row {
  position: absolute;
  bottom: 0;
  padding-bottom: 1rem;
  width: 90%;
}
</style>
